<template>
  <CRow class="weather-wrapper position-relative overflow-hidden">
    <vue-element-loading
      :active="loading"
      text="دریافت اطلاعات هواشناسی..."
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <div class="progress_page">
      <h4>در حال به روز رسانی این بخش هستیم ...</h4>
      <h5>از شکیبایی شما سپاسگزاریم</h5>
    </div>
  </CRow>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "WeatherForm",
  props: {
    farm: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions("weather", ["GetMessage", "GetWeather"]),
  },
  mounted() {
    // console.log("fa", this.farm);
  },
};
</script>

<style scoped>
.progress_page {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: whitesmoke;
}
.progress_page h4 {
  position: absolute;
  right: 31%;
  top: 30%;
  font-size: 2rem;
  color: #f80;
}
.progress_page h5 {
  position: absolute;
  right: 35%;
  top: 36%;
  font-size: 1rem;
  color: #2196f3;
}
</style>
